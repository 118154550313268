<template>
    <div class="nav">

    <div class="main-menu menu-fixed menu-light menu-accordion menu-shadow" data-scroll-to-active="true">
        <div class="navbar-header">
            <ul class="nav navbar-nav flex-row">
                <li class="nav-item mr-auto">
                    <a class="navbar-brand"><span class="brand-logo"></span>
                        <img :src="require('../assets/crvena.png')" class="logo-zameni">
                    </a></li>
                <li class="nav-item nav-toggle"><a class="nav-link modern-nav-toggle pr-0" data-toggle="collapse"><i class="d-block d-xl-none text-primary toggle-icon font-medium-4" data-feather="x"></i><i class="d-none d-xl-block collapse-toggle-icon font-medium-4  text-primary" data-feather="disc" data-ticon="disc"></i></a></li>
            </ul>
        </div>
        <div class="shadow-bottom"></div>
        <div class="main-menu-content">
            <ul class="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
                <li class=" navigation-header"><span data-i18n="Apps &amp; Pages">Glavni Meni</span><i data-feather="more-horizontal"></i></li>
                <li class=" nav-item"><a class="d-flex align-items-center"><span class="menu-title text-truncate" data-i18n="Email"><router-link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>Pocetna</router-link></span></a></li>
                <li v-if="rank!='korisnik'" class=" nav-item"><a class="d-flex align-items-center"><span class="menu-title text-truncate" data-i18n="Email"><router-link to="/korisnici"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>Korisnici</router-link></span></a></li>
                <li v-if="rank=='korisnik'" class=" nav-item"><a class="d-flex align-items-center"><span class="menu-title text-truncate" data-i18n="Email"><router-link to="/testovi"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 30 30" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-square"><polyline points="9 11 12 14 22 4"></polyline><path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path></svg>Testovi</router-link></span></a></li>
                <li v-if="rank!=='korisnik'" class=" nav-item"><a class="d-flex align-items-center"><span class="menu-title text-truncate" data-i18n="Email"><router-link to="/testoviLista"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 30 30" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-square"><polyline points="9 11 12 14 22 4"></polyline><path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path></svg>Testovi</router-link></span></a></li>
                <li v-if="rank=='korisnik'" class=" nav-item"><a class="d-flex align-items-center"><span class="menu-title text-truncate" data-i18n="Email"><router-link to="/prvapomoc"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-square"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>Prva Pomoc</router-link></span></a></li>
                <li v-if="rank!='korisnik'" class=" nav-item"><a class="d-flex align-items-center"><span class="menu-title text-truncate" data-i18n="Email"><router-link to="/uplate"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>Uplate</router-link></span></a></li>
               <!-- <li class=" nav-item"><a class="d-flex align-items-center"><span class="menu-title text-truncate" data-i18n="Email"><router-link to="/postavke"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-tool"><path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z"></path></svg>Postavke</router-link></span></a></li> -->
                <li class=" nav-item"><a class="d-flex align-items-center" v-on:click="Logout()"><span class="menu-title text-truncate" data-i18n="Email"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>Logout</span></a></li>
            </ul>
        </div>

        </div>
    </div>
</template>
<script>
  export default {
    name: 'Nav',
    data() {
        return {
            jwt: localStorage.getItem('jwt'),
            User: localStorage.getItem('User'),
            rank: localStorage.getItem('rank'),
        }  },
      methods:{
        Logout: function(){
            localStorage.removeItem('jwt')
            localStorage.removeItem('User')
            localStorage.removeItem('rank')
            localStorage.removeItem('uid')
            // re-init intercom with user info

            this.$router.go(this.$router.currentRoute)
      }
      }
  }
</script>
<style lang="scss" scoped>
    nav {
        margin-top: 25px;
        margin-bottom: 30px;
        color: red;
        a {
            font-weight: bold;
            color: red !important;
            &.router-link-exact-active {
                color: red;
            }
        }
    }
    li.nav-item {
        list-style: none;
    }
    .navigation li a i, .navigation li a svg {
        height: 23px;
        width: 22px;
        font-size: 20px;
        color: red;
        margin-right: 1.38rem;
    }
    a.router-link-exact-active.router-link-active {
        color: red !important;
    }
    .main-menu.menu-light .navigation li a {
        color: #625F6E;
        padding: 10px 15px 10px 15px;
        line-height: 1.45;
        color: red !important;
    }
    a.navbar-brand {
        padding: 0px !important;
        height: auto !important;
        margin: 0px !important;
    }
</style>
