<style lang="scss">
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    .btn-primary {
      border-color: #990f04 !important;
      background-color: #990f04 !important;
      color: #FFFFFF !important;
    }
    .form-control:focus {
      color:  #990f04;
      background-color: #FFFFFF;
      border-color:  #990f04;
      outline: 0;
      box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
    }
  }

  html .content.app-content {
    padding: 0px !important;
  }
  @media (max-width: 575.98px){
    .nav {
      display: none !important;
    }
    html .content {
      margin-left: 260px !important;
    }
    html .content.app-content {
      padding: 0px !important;
      padding: 0px !important;
      margin: 0px !important;
      width: 100%;
    }
  }
</style>
<template>
  <div id="app">
      <div id="nav" v-if="(jwtToken && jwtTokenValid)">
        <Nav v-if="(jwtToken && jwtTokenValid)"/>
        <div class="app-content content ">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-body">
              <router-view default="{name: 'Dashboard'}" v-if="(jwtToken && jwtTokenValid)"/>
            </div>
          </div>
        </div>


      </div>
    <Login v-if="!(jwtToken && jwtTokenValid)" />
  </div>
</template>
<script>
  import Vue from 'vue'
  import axios from 'axios'

  import Nav from './components/Nav.vue';
  import Login from './views/Login.vue';
  import Dashboard from './views/Dashboard.vue';

  Vue.component('Nav', Nav);
  Vue.component('Login', Login);
  Vue.component('Dashboard', Dashboard);
  export default {
    name: 'app',
    data() {
      return {
        jwtToken: false,
        jwtTokenValid: false,
        systemRole:"user",
      }
    },
    created() {
      let self = this;
      Vue.prototype.initAxios = function () {
        self.initAxiosRightWay();
      };
      Vue.prototype.initAxios();

      this.handleAuth()
    },
    methods: {
      initAxiosRightWay() {
        if (this.jwtToken && this.jwtToken.length > 0 && (!this.$axios || !this.$axios.headers)) {
          let instance = axios.create({
            baseURL: process.env.VUE_APP_SERVER_ROOT + process.env.VUE_APP_API_PATH,
            timeout: 60000,
            headers: {
              'Authorization': 'Bearer ' + this.jwtToken
            }
          });

          Vue.prototype.$axios = instance;
        } else {
          let instance = axios.create({
            baseURL: process.env.VUE_APP_SERVER_ROOT + process.env.VUE_APP_API_PATH,
            timeout: 60000
          });
          Vue.prototype.$axios = instance;
        }
        // this.enableInterceptor();
      },
      handleAuth()
      {
        if(localStorage.getItem('jwt'))
        {
          this.jwtToken=true;
          this.jwtTokenValid =true;
        }
      }
    },
  }
</script>
