<style lang="scss">
    @media (max-width: 575.98px) {
        .Dashboard {
            padding-top: 20px;
            display: flex;
            flex-direction: column;
            li.nav-item {
                height: 50% !important;
                width: 50% !important;
                padding: 5px;
                margin-top: 20pt !important;

            }
        }
        .Dashboard li.nav-item .d-flex.align-items-center {
            font-size: 15pt !important;
            width: 100%;
        }
    }

    </style>
<template>
    <div class="Dashboard">
        <li v-if="rank!='korisnik'" class=" nav-item"><a class="d-flex align-items-center"><span class="menu-title text-truncate" data-i18n="Email"><router-link to="/korisnici">
            <div class="dashboard-item-holder"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>

            </div>
            <div class="dashboard-item-name">Korisnici</div>
        </router-link></span></a></li>
        <li v-if="rank=='korisnik'" class=" nav-item"><a class="d-flex align-items-center"><span class="menu-title text-truncate" data-i18n="Email"><router-link to="/testovi">
            <div class="dashboard-item-holder"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 30 30" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-square"><polyline points="9 11 12 14 22 4"></polyline><path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path></svg>

            </div>
            <div class="dashboard-item-name">Testovi</div>
        </router-link></span></a></li>
        <li v-if="rank!=='korisnik'" class=" nav-item"><a class="d-flex align-items-center"><span class="menu-title text-truncate" data-i18n="Email"><router-link to="/testoviLista">
            <div class="dashboard-item-holder"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 30 30" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-square"><polyline points="9 11 12 14 22 4"></polyline><path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path></svg>

            </div>
            <div class="dashboard-item-name">Testovi</div>
        </router-link></span></a></li>
        <li v-if="rank=='korisnik'" class=" nav-item"><a class="d-flex align-items-center"><span class="menu-title text-truncate" data-i18n="Email"><router-link to="/prvapomoc">
            <div class="dashboard-item-holder"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-square"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>

            </div>
            <div class="dashboard-item-name">Prva Pomoc</div>
        </router-link></span></a></li>
        <li v-if="rank!='korisnik'" class=" nav-item"><a class="d-flex align-items-center"><span class="menu-title text-truncate" data-i18n="Email"><router-link to="/uplate">
            <div class="dashboard-item-holder"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>

            </div>
            <div class="dashboard-item-name">Uplate</div>
        </router-link></span></a></li>
      <!--  <li class=" nav-item"><a class="d-flex align-items-center"><span class="menu-title text-truncate" data-i18n="Email"><router-link to="/postavke">
            <div class="dashboard-item-holder"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-tool"><path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z"></path></svg>

            </div>
            <div class="dashboard-item-name">Postavke</div>
        </router-link></span></a></li>--->
        <li class=" nav-item"><a class="d-flex align-items-center" v-on:click="Logout()"><span class="menu-title text-truncate" data-i18n="Email">
            <div class="dashboard-item-holder"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>

            </div>
            <div class="dashboard-item-name">Logout</div>
        </span></a></li>
    </div>
</template>
<script>
  export default {
    name: 'Dashboard',
      mounted(){
          console.log( "PATH JE");
          console.log( "!"+this.$route.path+"!");
      },
      data() {
          return {
              jwt: localStorage.getItem('jwt'),
              User: localStorage.getItem('User'),
              rank: localStorage.getItem('rank'),
          }  },
      methods:{
          Logout: function(){
              localStorage.removeItem('jwt')
              localStorage.removeItem('User')
              localStorage.removeItem('rank')
              localStorage.removeItem('uid')
              // re-init intercom with user info

              this.$router.go(this.$router.currentRoute)
          },
          isDashboard() {
              console.log( this.$route.path);
          }
      },
    components: {
    }
  }
</script>
<style lang="scss">
    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
    }
    .Dashboard {
        padding-top: 20px;
        display: flex;
        li.nav-item {
            list-style-type: none;
            width: 300px;
            border: 1px solid red;
            height: 250px;
            font-size: 30px;
            margin: auto;
            border-radius: 15pt;
            background: #fb0000;
            display: flex;
            .d-flex.align-items-center {
                font-size: 50px;
            }
            a svg {
                height: 50px;
                width: 50px;
                font-size: 50px;
                margin: auto;
                display: block;
                color: white !important;
                svg.feather {
                    color: red !important;
                }
            }


        }
    }
    span.dashboard-item-holder.dahsboard-menu-item-name {
        font-size: 25pt;
        color: white !important;
    }
    .dashboard-item-holder {
        color: white;
    }
    .Dashboard li.nav-item .d-flex.align-items-center {
        font-size: 50px;
        width: 100%;
        span.menu-title.text-truncate {
            width: 100%;
            a {
                width: 100%;
                text-align: center;
                color: white;
            }
        }
        .dashboard-item-name {
            width: 100%;
            text-align: center;
            color: white;
        }
    }
</style>
