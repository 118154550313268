import auth from './middleware/auth'

//import Home from './views/home.vue'
import Dashboard from './views/Dashboard.vue'
export const routes =
  [
    {
      path: '/',
      name: 'Dashboard',
      component: Dashboard,
      meta: {
        middleware: auth,
      },
    },
    {
      path: '/korisnici',
      name: 'korisnici',
      component: () => import('./views/Korisnici.vue'),
      meta: {
        middleware: auth,
      },
    },


      {
          path: '/korisnici/:id',
          name: 'view-korisnik',
          component: () => import('./views/KorisniciView.vue'),
          meta: {
              middleware: auth,
          },
      },

      {
          path: '/korisnici/:id/edit',
          name: 'korisnici',
          component: () => import('./views/KorisniciEdit.vue'),
          meta: {
              middleware: auth,
          },
      },
      {
          path: '/testovi/:id/polganje/:uid',
          name: 'TestoviPolaganje',
          component: () => import('./views/TestoviPolaganje.vue'),
          meta: {
              middleware: auth,
          },
      },
      {
          path: '/testovi/:id/pregled/:uid',
          name: 'TestoviPregled',
          component: () => import('./views/TestoviPregled.vue'),
          meta: {
              middleware: auth,
          },
      },

      {
          path: '/testovi',
          name: 'testovi',
          component: () => import('./views/Testovi.vue'),
          meta: {
              middleware: auth,
          },
      },
      {
          path: '/prvapomoc',
          name: 'prvapomoc',
          component: () => import('./views/prvapomoc.vue'),
          meta: {
              middleware: auth,
          },
      },
      {
          path: '/testoviLista',
          name: 'pitanja',
          component: () => import('./views/Pitanja.vue'),
          meta: {
              middleware: auth,
          },
      },
      {
          path: '/uplate',
          name: 'uplate',
          component: () => import('./views/Uplate.vue'),
          meta: {
              middleware: auth,
          },
      },
      {
          path: '/rezultati',
          name: 'rezultati',
          component: () => import('./views/Rezultati.vue'),
          meta: {
              middleware: auth,
          },
      },
      {
          path: '/postavke',
          name: 'postavke',
          component: () => import('./views/Postavke.vue'),
          meta: {
              middleware: auth,
          },
      },

  ]
