import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'jquery'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'bulma/css/bulma.css';

  import "./app-assets/vendors/css/vendors.min.css"
  import "./app-assets/css/bootstrap.css"
  import "./app-assets/css/bootstrap-extended.css"
  import "./app-assets/css/colors.css"
  import "./app-assets/css/components.css"
  import "./app-assets/css/themes/dark-layout.css"
  import "./app-assets/css/themes/bordered-layout.css"
  import "./app-assets/css/themes/semi-dark-layout.css"
  import "./app-assets/css/core/menu/menu-types/vertical-menu.css"
  import "./app-assets/css/plugins/forms/form-validation.css"
  import "./app-assets/css/pages/page-auth.css"
  import "./assets/css/style.css"
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
