<template>
  <div class="login">
    <!-- BEGIN: Content-->
    <div class="app-content content login">
      <div class="content-wrapper">
        <div class="content-header row">
        </div>
        <div class="content-body">
          <div class="auth-wrapper auth-v1 px-2">
            <div class="auth-inner py-2">
              <!-- Login v1 -->
              <div class="card mb-0 background-main-card">
                <div class="card-body">
                  <a class="navbar-brand"><span class="brand-logo"></span>
                    <img :src="require('../assets/crvena.png')" class="logo-zameni">
                  </a>
                  <h4 class="card-title mb-1">Dobrodosli u Rotor App</h4>
                  <p class="card-text mb-2">Molimo vas da se logujete</p>

                  <form class="auth-login-form mt-2" @submit.prevent="login">
                    <div class="form-group">
                      <label for="login-email" class="form-label">Email</label>
                      <input type="text" class="form-control" v-model="userEmail" id="login-email" name="login-email" placeholder="john@example.com" aria-describedby="login-email" tabindex="1" autofocus="">
                    </div>

                    <div class="form-group">
                      <div class="d-flex justify-content-between">
                        <label for="login-password">Password</label>
                      </div>
                      <div class="input-group input-group-merge form-password-toggle">
                        <input type="password" v-model="userPassword" class="form-control form-control-merge" id="login-password" name="login-password" tabindex="2" placeholder="············" aria-describedby="login-password">
                        <div class="input-group-append" v-on:click="switchVisibility()">
                          <span class="input-group-text cursor-pointer"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg></span>
                        </div>
                      </div>
                    </div>
                    <button class="btn btn-primary btn-block waves-effect waves-float waves-light" tabindex="4">Login</button>
                  </form>






                </div>
              </div>
              <!-- /Login v1 -->
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
//  import api from "../api"
  import axios from 'axios'
  import Vue from 'vue'
export default {
  name: 'Login',
  props: {
    msg: String
  },
  data() {
    return {
      userEmail: "",
      userPassword: "",
      ErrorLogin:false,
      RememberMe:false,
      mode: process.env.VUE_APP_ENVIRONMENT,
      showResetSuccess: false,
      showActivationMessage: false
    }
  },
  methods:{
    login: function () {
      let server = process.env.VUE_APP_SERVER_ROOT + process.env.VUE_APP_API_PATH + process.env.VUE_APP_LOGIN_API_PATH;
      let self = this;
      let config = {
        url: server,
        data: {
          email: this.userEmail,
          password: this.userPassword,
        },
        method: "POST"
      };
      axios(config)
              .then(function (response) {
                let loginResponse = response.data;

                Vue.prototype.initAxios();
                localStorage.setItem('jwt',loginResponse.access_token)
                localStorage.setItem('User',loginResponse.User)
                localStorage.setItem('rank',loginResponse.User.rank)
                localStorage.setItem('uid',loginResponse.User.id)
                // re-init intercom with user info

                self.$router.go(self.$router.currentRoute)

              })
              .catch(function (error) {
                console.log("error", error)
                self.ErrorLogin = true;
              });
      return '';
    },
    switchVisibility()
    {
      const passwordField = document.querySelector('#login-password')
      if (passwordField.getAttribute('type') === 'password') passwordField.setAttribute('type', 'text')
      else passwordField.setAttribute('type', 'password')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.auth-wrapper.auth-v1 .auth-inner {
  max-width: 400px;
}
.auth-wrapper.auth-v1 .auth-inner:before {
  width: 0px;
  height: 0px;
  content: ' ';
  position: absolute;
  top: 0px;
  left: 0px;
  background-image: none;

}
.input-group:not(.bootstrap-touchspin):focus-within .form-control, .input-group:not(.bootstrap-touchspin):focus-within .input-group-text {
  border-color: #990f04!important;
  box-shadow: none;
}
</style>
